// import site configuration and other utilities
import { SiteConfig, Utilities as util }    from './util';

// import user interface components
import { ScrollControl } from './components/ScrollControl';
import ResponsiveEvents from './components/ResponsiveEvents';
import NewsLetter from './components/NewsLetter';
import InteractiveDemo from './components/InteractiveDemo';
import FormHandler from './components/FormHandler';
import Carousel from './components/Carousel';
import EasterEgg from './components/EasterEgg';
import ImageZoom from './components/ImageZoom';

$(function() {

  // declarations of initialized modules (to check against availability/existence when we want to reach them in other parts of code)
  var scrollControl,
      navScrollEvt,
      sideScrollEvt,
      primaryHeader,
      responsiveEvents,
      newsLetter,
      ImageZoom;

  // this will prompt the user if we may use theirs location. This is used
  // for automagic switching between day/night mode. If given permission, the browser
  // will remember this choice for the user and won't prompt again. At any time
  // any user can opt-out of this setting. Note: this is NOT a cookie, but an
  // in-browser user preference, like localStorage.
  // util.getWebsiteTime(data => {
  //
  //   if(data.err) {
  //     // something illegal happened. cannot proceed.
  //     console.log(data.err);
  //     return;
  //   }
  //
  //   if(data.sunset) {
  //     $("body").addClass('theme-night');
  //   } else {
  //     $("body").removeClass("theme-night");
  //   }
  //
  // });

  /* ==============================
    Module availability detection
    - defines what is and what is not on the currently rendered page
    - uses jQuery for result set detection
   ============================== */
  SiteConfig.page.header = $("#primary-header").length !== 0;
  SiteConfig.page.sidebar = $("#primary-sidebar").length !== 0;
  SiteConfig.page.nav = $("#primary-nav").length !== 0;
  SiteConfig.page.blogArticle = $("#blog-article").length !== 0;
  SiteConfig.page.teamMembers = $(".team-members").length !== 0;
  SiteConfig.page.form = $(".form").length !== 0;
  SiteConfig.page.carousel = $(".carousel").length !== 0;
  SiteConfig.page.consent = $("#privacy-consent").length !== 0;

  /* ==============================
    ResponsiveEvents initialisation
   ============================== */
  responsiveEvents = new ResponsiveEvents({
    breakpoints: SiteConfig.responsive
  });

  responsiveEvents.smallerThan('phone', function() {
    SiteConfig.util.phoneIn();

    if(sideScrollEvt) {
      // resets the current style
      sideScrollEvt.element.style.transform = '';
      // disabled scrollControl temporarily
      sideScrollEvt.hold = true;
    }

  }).biggerThan('phone', function() {

    SiteConfig.util.phoneOut();

    if(sideScrollEvt) {
      sideScrollEvt.hold = false;
      sideScrollEvt.update(document.documentElement.scrollTop || document.body.scrollTop, false);
    }

  });

  responsiveEvents.smallerThan('small-desktop', function() {
    if(sideScrollEvt) sideScrollEvt.treshold = -60;
  }).biggerThan('small-desktop', function() {
    // reset to initial treshold
    if(sideScrollEvt) sideScrollEvt.treshold = -80;
  });

  responsiveEvents._check();

  /* ==============================
    Animatable scroll-to utility from ScrollControl
   ============================== */
  ScrollControl.initScrollTo();

  /* ==============================
    ScrollControl initialisation
   ============================== */
  var scrollControl = new ScrollControl();

  /*
    Navbar scrolling handler [ScrollControl]

    - when the user scrolls past the navbar, it should become 'sticky' to the top
      of the document.

    - when the user scrolls back, it should snap back to its original position on the page
  */
  if(SiteConfig.page.nav) {

    // the ScrollControl delivers a sub-event manager, create one for the navbar
    navScrollEvt  = scrollControl.registerScrollEvent(document.getElementById('primary-nav'), {
                          autoTreshold: true,
                          autoTresholdCorrection: -20
                        });

    // listen to scrolling PAST the navbar - this callback will fire once.
    navScrollEvt.on(function(element, e) {
      element.classList.add('nav__fixed');
      setTimeout(function() {
        element.classList.add('in');
      }, 100);
    }).off(function(element, e) {
      element.classList.remove('nav__fixed');
      element.classList.remove('in');
    }).update(document.documentElement.scrollTop || document.body.scrollTop, null);

  }

  /*
    Sidebar scrolling handler [ScrollControl]

    - when the user scrolls down, it should pick up and make the sidebar fixed to the side - in
      some way, at least. position fixed is not the option to go with here as this would destroy
      the grid. I think this should be an 'auto-scroll-each' kinda deal.
    - As the user scrolls, this element should remain there.
    - As the user reaches the bottom of the page, it should stop being fixed, otherwise the user
      will never be able to reach the bottom of this content.
   */
  if(SiteConfig.page.sidebar) {

    // this is only executed on certain conditions, and thus wrapped in its
    // own function. The equivalent of this function is also triggered when these
    // conditions are met, to keep responsive mechanics as snappy and predictable
    // as possible.
    function initSidebar() {

      // don't do this if we're on a phone.
      // if(window.innerWidth <= SiteConfig.responsive.phone) {
      //   return;
      // }

      // calculate the 'max stick' limit
      function maxScrollDown(extraElems = []) {
        let sidebar = $("#primary-sidebar");
        let footer = $(".website__footer");
        let footerHeight = footer.height();
        let sidebarHeight = sidebar.height();
        let documentHeight = $('body').height();
        let extra = 0;

        // fix for the vendor reference block
        if($("section.vendor-reference").length) {
          let vr = $("section.vendor-reference")
          extra += vr.height() + 60;
        }

        return documentHeight - footerHeight - sidebarHeight - extra;
      }

      // calculates the sidebar bottom freezing distance
      function freezeTop(scrollPos, scrollLimit) {
        let sidebar = $("#primary-sidebar")[0];
        sidebar.style.marginTop = `-${scrollPos - scrollLimit + 80}px`;
      }

      let snapParent = $("#primary-sidebar").parent(),
          snapLimit = snapParent.offset().top,
          scrollLimit = maxScrollDown(),
          sideScrollEvt = scrollControl.registerScrollEvent(document.getElementById('primary-sidebar'), {
                            treshold: -80
                          });

      // listen to scrolling FROM the treshold point, and fire a callback on every scroll hit after that
      sideScrollEvt.from(function(element, scrollPos, e) {

        // if(window.innerWidth <= SiteConfig.responsive.phone) {
        //   return;
        // }

        // stop scrolling if we the sidebar hits the bottom of the body container
        // (stopLimit tells us the distance TO that bottom point, relative to the bottom of the sidebar, so 0 = stop)
        if(scrollPos > snapLimit) {
          // element.style.top = `${transformY}px`;
          element.style.width    = `${element.parentNode.getBoundingClientRect().width}px`;
          element.style.top      = `77px`;
          element.style.position = 'fixed';
          element.style.marginTop = `0`;
        } else {
          element.style.width     = 'auto';
          element.style.top       = ``;
          element.style.position  = 'absolute';
          element.style.marginTop = `0`;
        }

        if(scrollPos > scrollLimit) {
          freezeTop(scrollPos, scrollLimit);
        }

      }).update(document.documentElement.scrollTop || document.body.scrollTop, null);

    };

    if(!(window.innerWidth <= SiteConfig.responsive.phone)) {
      initSidebar();
    }

  }

  // epic header parralax effect
  if(SiteConfig.page.header) {

    primaryHeader = document.getElementById('primary-header');

    scrollControl.on(function(scrollPos, e) {

      var dimensions = primaryHeader.getBoundingClientRect();

      var height = dimensions.height;
      var posPercent = 100 / dimensions.height * scrollPos;

      if(posPercent < 100 && posPercent > 0) {
        $(primaryHeader).find('.website__container').css('transform',`translateY(${posPercent/10}px)`);
      }

      if(posPercent < 10) {
        $(primaryHeader).find('.website__container').css('transform',`translateY(0px)`);
      }

    });

  }

  /* ==============================
    NavHandle bind
   ============================== */

  // attach a click listener to the navigation handle
  $(".nav__handle").bind('click', function(e) {
    e.preventDefault();
    var parent = $(this).parents('.website__nav');
    parent.toggleClass('open');

    if(parent.hasClass('open')) {
      SiteConfig.state['mobile-nav-open'] = true;
      SiteConfig.ui.blurIn();
      $("body").addClass('no-scroll');
    } else {
      SiteConfig.state['mobile-nav-open'] = false;
      SiteConfig.ui.blurOut();
      $("body").removeClass('no-scroll');
    }

  });

  // attach a body click listener to close the navigation
  // if we tap somewhere outside the navigation field
  $('body').bind('click touchstart', function(e) {

    if(SiteConfig.state['mobile-nav-open'] === true) {
      if($(e.target).parents('.website__nav').length == 0) {
        $(".website__nav").removeClass('open');
        SiteConfig.state['mobile-nav-open'] = false;
        SiteConfig.ui.blurOut();
      }
    }

  });

  if(SiteConfig.page.blogArticle) {

    // invert contrast toggle
    $("#invert-contrast").on('change', function(e) {
      var checked = $(this).is(':checked');
      if(checked) {
        $(".blog-article__content").addClass('inverted');
      } else {
        $(".blog-article__content").removeClass('inverted');
      }
    });

    // change font type toggle
    $("#change-font").on('change', function(e) {
      var checked = $(this).is(':checked');
      if(checked) {
        $(".blog-article__content").addClass('serif');
      } else {
        $(".blog-article__content").removeClass('serif');
      }
    });

    // displays your current paragraph while reading - this may come in really handy for
    // big articles, since the viewport is pretty big, subtitles will not always be there.
    // this little utility will help navigating your way inside the blog article. #smart
    var articleTitles = $("#blog-article h1");

    scrollControl.on(function(scrollPos, e) {

      var vwHeight = $(window).outerHeight();
      var closest = null;
      var lastFound = null;

      articleTitles.each(function() {
        var titlePos = $(this).offset().top;

        if(scrollPos > titlePos - (vwHeight/2)) {
          lastFound = $(this);
        }

      });

      if(lastFound) {
        $(".blog-article__current-paragraph > p [data-placeholder='paragraph']").text(lastFound.text());

        if($(".blog-article__current-paragraph").hasClass('out')) {
          $(".blog-article__current-paragraph").removeClass('out');
        }
      } else {
        $(".blog-article__current-paragraph").addClass('out');
      }

      if(scrollPos > $(".blog-article").outerHeight() - 500) {
        $(".blog-article__current-paragraph").addClass('out');
      }

    });

    // force article external links to open in a new tab/window
    $("#blog-article").find('a.external').bind('click', e => {
      let url = $(e.currentTarget).attr('href');
      if(url.indexOf('mailto:') == -1) {
        e.preventDefault();
        window.open(url,'_blank');
      }
    });

  }

  /*
    Team members interactivity handlers
   */
  if(SiteConfig.page.teamMembers) {

    // closes open bubble content
    function closeBubbles() {
      $('.team-members').find('.about').removeClass('open');
    }

    // opens bubble content
    function openBubble($link) {
      if(!$link.parent().hasClass('open')) {
        closeBubbles();
        $link.parent().addClass('open');
      } else {
        $link.parent().removeClass('open');
      }
    }

    // toggle speech bubble content
    $(".team-members .about > a").bind('click', e => {
      e.preventDefault();
      openBubble($(e.currentTarget));
    });

  }

  /*
    Form handling
   */
  if(SiteConfig.page.form) {

    $(".form").each((i, el) => {
      let handler = new FormHandler(el, {
        captcha_key: SiteConfig.credentials.RECAPTCHA_KEY
      });
    });

  }

  /*
    News letter subscription control
   */
  if($(".subscribe-news-letter").length) {
    newsLetter = new NewsLetter($(".subscribe-news-letter"));
  }

  /*
    Interactive Demo construction
   */
  if($("#interactive-demo").length) {
    var demo = new InteractiveDemo($("#interactive-demo"), {

    });
  }

  /*
    Carousel construction
   */
  if(SiteConfig.page.carousel) {
    $(".carousel").each((i, el) => {
      let car = new Carousel($(el), {
        autoplay: $(el).data('autoplay'),
        autoplayInterval: $(el).data('autoplayInterval')
      });

      $(el).data('carousel', car);
    });
  }

  /*
    ImageZoom jQuery init
   */
  if(SiteConfig.page.blogArticle) {
    $(".blog-article__content img").ImageZoom({
      template: Handlebars.templates['image-zoom']
    });
  }

  /*
    Easter egg
   */
  if(Konami) {
    // U U D D L R L R B A
    let easterEgg = new EasterEgg();
  }


  // Cookie detection
  // - adds a mark in the cookie table if they're present at this moment.

  (function() {

    let check = ["_ga","_gid","_gat","ga-disable"];
    let eraseOptOut = ["_ga","_gid","_gat"];

    let cookies = () => {
      let _cookies = document.cookie.split("; ");
      let ret = {};
      _cookies.forEach(cookie => {
        let split = cookie.split("=");
        ret[split[0]] = split[1];
      });
      return ret;
    }

    let removeCookie = name => document.cookie = `${name}+=; Max-Age=-99999999`;

    let cookiesObj = cookies();

    for(var _c in check) {

      if(cookiesObj[check[_c]]) {
        $(`[data-cookie=${check[_c]}]`).append("<i class='fa fa-check'></i>");
      }

      if(check[_c] == "ga-disable") {
        for(var c in cookiesObj) {
          if(c.indexOf('ga-disable') > -1) {
            $(`[data-cookie="ga-disable"]`).append("<i class='fa fa-check'></i>");
          }
        }
      }

    }

  }());

  /*
    Privacy consent handler
   */
  // if(SiteConfig.page.consent) {
  //
  //   let consent = $("#privacy-consent");
  //
  //   let completeConsent = () => {
  //     window.localStorage.setItem('consented', true);
  //     $("#privacy-consent").addClass('out');
  //   }
  //
  //   if(!window.localStorage.getItem('consented') === true) {
  //     // $("#privacy-consent").parent('.notification-dialog-container').removeClass('hidden');
  //     setTimeout(() => {
  //       $("#privacy-consent").removeClass('out');
  //     }, 1000);
  //   }
  //
  //   consent.find('a').bind('click touchstart', e => {
  //     e.preventDefault();
  //     let tgt = e.currentTarget;
  //     if(tgt.hasAttribute('data-to-privacy')) {
  //       // handle navigating to privacy page
  //       completeConsent();
  //       window.location.href = '/this-page-is-obligated-by-law';
  //     }
  //     if(tgt.hasAttribute('data-dismiss')) {
  //       completeConsent();
  //     }
  //   })
  // }

});

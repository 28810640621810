import CUCKOO_DEMO from '../interactive-demo-config';

/*
  A primitive class for controlling the micro-terminal. This class will perform
  pre-defined actions based on user input and will 'process' the dropped element
  inside the dropzone.

  @arg el - container of the micro terminal
  @arg demo - a reference to the InteractiveDemo instance
 */
class MicroTerminal {

  constructor(el, demo) {

    let self = this;

    this.demo = demo;
    this.el = el;
    this._$input = this.el.find('input');

    this._$input.bind('keypress', function(e) {
      if(e.which === 13) {
        self.exec($(this).val());
      }
    });

  }

  // executes a command
  exec(cmd) {

    // validate command
    // 1. arg1 === CUCKOO_DEMO.COMMAND_NAMESPACE
    // 2. arg2 === CUCKOO_DEMO.AVAILABLE_COMMANDS
    // 3. arg3 === PARAMETERS
    let args = cmd.split(' ');

    // check #1
    if(args[0] !== CUCKOO_DEMO.COMMAND_NAMESPACE) {
      return this.err('Not a cuckoo command.');
    }

    // check #2
    if(!CUCKOO_DEMO.AVAILABLE_COMMANDS[args[1]]) {
      return this.err('Undefined command for package "cuckoo".');
    }

    console.log('validation complete, execute.');

  }

  // error the command
  err(msg) {
    console.error(msg);
    return false;
  }

}

/*
  A primitive class for controlling the dropzone and its dropping
  behavior. It needs to accept items that have been dragged from SampleList

  @arg el - container of the dropzone
  @arg demo - reference to the InteractiveDemo class instance
 */
class SampleDropzone {

  constructor(el, demo) {

    let self = this;

    this.demo = demo;
    this.el = el;

    this.el.droppable({
      drop: function(evt, ui) {
        self.accept(ui.draggable);
      },
      hoverClass: 'drop-over'
    });

  }

  // fired when a sample has been dropped. this will pass in
  // the sample data linked to that draggable item
  accept(draggable) {

    // reset all states
    this.reset();

    // extract the sample from the element
    let sample = draggable.data('sample');

    draggable.parent('li').addClass('selected');

    // some validation should occur here... or something.
    this.el.addClass('drop-dropped');

    let template = Handlebars.templates['dropzone-content-active']({
      task_id: sample.id,
      filename: sample.name,
      icon: CUCKOO_DEMO.ICON_TRANSLATE[sample.type] || CUCKOO_DEMO.ICON_TRANSLATE['default']
    });

    this.el.find('.dropzone-content').html(template);

  }

  // resets the dropzone to initial state
  reset() {
    
    // reset dropzone states
    this.el.removeClass('drop-dropped');
    this.el.find('.dropzone-content').html(Handlebars.templates['dropzone-content-active']);

    // reset selected class in samplelist
    this.demo.sampleList.el.find('li').removeClass('selected');
  }

}

/*
  A primitive class for controlling the sample list and their drag / custom
  upload behaviors

  @arg el - container of the sample list
  @arg demo - a reference to the instantiated Demo class
 */
class SampleList {

  constructor(el, demo) {

    this.demo = demo;
    this.el   = el;

    // generate samples from the preset list
    el.prepend(this.createSamples());

    // makes the files draggable
    el.find('li:not(.custom-upload) a').draggable({
      revert: true
    });
  }

  // creates the samples from the CUCKOO_DEMO.SAMPLES array
  // returns an array with jQuery objects, so can be handled with
  // jQuery.append / jQuery.prepend
  createSamples() {
    var elems = [];

    CUCKOO_DEMO.SAMPLES.forEach(function(sample) {

      let _$li = $("<li />");
      let _$a = $("<a />", {
        text: sample.name
      });
      let _$i = $("<i />", {
        class: `fa ${CUCKOO_DEMO.ICON_TRANSLATE[sample.type] || CUCKOO_DEMO.ICON_TRANSLATE['default']}`
      });

      _$li.append(_$a);
      _$a.prepend(_$i);

      // store the sample data onto the [draggable] element itself.
      _$a.data('sample', sample);

      elems.push(_$li);

    });

    return elems;
  }

}

/*
  Constructor for the entire interactive demo. All sub-processes
  will be controlled here, and any functionality will be split up
  into several sub-classes to each handle their specific thing and makes
  it available to other classes by referring this base class to each class
  under a property 'demo'.

  @arg container - the container of the whole thing
  @arg options - some specific fine-tune or user-specific options can be passed here

 */
class InteractiveDemo {

  constructor(container, options) {

    // extend default options with user defined options
    this.options = $.extend({

    }, options || {});

    // references the sample list class
    this.sampleList = new SampleList(container.find('.sample-list'), this);
    this.sampleDropzone = new SampleDropzone(container.find('.dropzone'), this);
    this.microTerminal = new MicroTerminal(container.find('.terminal'), this);
  }

}

export default InteractiveDemo;

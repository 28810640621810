// deps
import { Utilities } from '../util';
import { ScrollControl } from './ScrollControl';

/*
  Collection of different validators. Allows for custom validation of any field.
 */
const validators = {
  // updates field values
  update: function(field) {
    field.value = field.input.val();
  },
  // validates plain text input
  text: function(field) {
    validators.update(field);
    return field.value.length > 0;
  },
  email: function(field) {
    validators.update(field);
    return Utilities.validateEmail(field.value);
  }
}

/*
  maps fields in a form to single object
  with other parameters for validation etc.
  */
function mapFields(form) {
  var ret = {};

  form.find('input,textarea').each((i, el) => {
    var el = $(el);
    var name = el.attr('name');

    // name property is mandatory
    if(name) {
      ret[name] = {
        input: el, // the input element
        parent: el.parents('fieldset'), // parent container element
        type: el.attr('type') || "text", // type of the input field
        value: el.attr('value') || "", // value of the input field
        name: el.attr('name'), // name of the input field
        validate: el.attr('data-validate') !== undefined, // only validate fields with data-validate
        validated: false // flags if the input is correct
      }
    }
  });

  return ret;
}

/*
  Blink effect on a single div, toggles a 'highlight' class with an interval
  */
function highlight(el, duration = 1000) {
  if(el.hasClass('highlight')) return;
  el.addClass('highlight');
  setTimeout(function() {
    el.removeClass('highlight');
  }, duration);
}

/*
  Renders a captcha
  */
function captcha(form, Recaptcha) {
  if(!form.el.length || !form.options.captcha_key.length || !Recaptcha) return false;
  form.captcha = Recaptcha.render('captcha', {
    'sitekey': form.options.captcha_key,
    'size': 'normal',
    'theme': 'dark'
  });
  form.usesCaptcha = true;
  return form.captcha;
}

/*
  Controls a form, applies validation etc.
  - needs jQuery
  @arg el [HTMLElement]
 */
export default class FormHandler {

  // constructor
  constructor(el, options = {}) {
    this.el           = $(el);
    this.options      = $.extend({
      captcha_key: null,
      url: null,
      method: null
    }, options);
    this.fields       = mapFields(this.el);
    this.usesCaptcha  = false;
    this.captcha      = null;
    this.initialise();
  }

  // initialise
  initialise() {
    let self = this;

    // determine the POST url of the form (if given as option, it will not do this.)
    if(this.options.url == null) {
      let action = this.el.attr('action');

      // if there is no action, just link to this page instead.
      if(!action) {
        action = window.location;
      }

      this.options.url = action;
    }

    // overrides for method - same idea as above
    if(this.options.method == null) {
      let method = this.el.attr('method');

      // assign default if not there, should be POST as default
      if(!method) {
        method = 'POST';
      }

      this.options.method = method;
    }

    // ... and override the recaptcha sitekey details the same way (from HTML)
    if(this.el.data('captchaSitekey') !== undefined) {
      this.options.captcha_key = this.el.data('captchaSitekey');
    }

    // attach listeners to the input fields for auto-validation
    for(let f in this.fields) {
      this.fields[f].input.bind('blur', e => {
        self.validate(self.fields[f]);
      });
    }

    // handle submission of the form
    this.el.bind('submit', e => {
      e.preventDefault();
      self.submit();
    });

    // render captcha
    if(this.options.captcha_key) {
      window.captcha_callbacks.push(function(c) {
        captcha(self, c);
      });
    }

  }

  // validate
  validate(field) {

    var validated = false;
    var validator = validators[field.type];

    // run a validator if this field needs to be validated
    if(field.validate) {
      if(validator && typeof validator === 'function') {
        validated = validator(field);
      }
    } else {
      validated = true;
    }

    field.validated = validated;

    // update ui
    if(validated) {
      field.parent.removeClass('not-validated');
      field.parent.addClass('validated');
    } else {
      field.parent.removeClass('validated');
      field.parent.addClass('not-validated');
    }

    return validated;
  }

  // summarizes all the fields and 'decides' if it's OK to post
  summarize() {
    var results = [];
    for(var f in this.fields) {
      results.push(this.fields[f].validated);
    }

    return !!(results.indexOf(false) == -1);
  }

  // submit
  submit() {
    var self = this;
    var checkEl;
    for(var f in this.fields) {
      if(!this.fields[f].validated) {
        var v = this.validate(this.fields[f]);
        if(!v) {
          checkEl = this.fields[f].parent;
          break;
        }
      }
    }

    if(checkEl) {
      ScrollControl.scrollTo(checkEl, function() {
        highlight(checkEl);
      });
    } else {

      // check captcha if captcha is enabled
      if(this.usesCaptcha) {
        var res = grecaptcha.getResponse(this.captcha);
        if(res.length) {
          this.send(this.complete.bind(this));
        } else {
          ScrollControl.scrollTo(this.el.find('#captcha'), function() {
            highlight(self.el.find('.captcha'));
          });
        }
      } else {
        this.send(this.complete.bind(this));
      }

    }
  }

  // serializes form input to json
  serialize() {
    var ret = {};
    for(var f in this.fields) {
      ret[this.fields[f].name] = this.fields[f].value;
    }

    if(this.usesCaptcha && grecaptcha) {
      let token = grecaptcha.getResponse(this.captcha);
      ret['recaptcha'] = token;
    }

    return ret;
  }

  // sends the form and calls a callback after send
  send(callback = function() {}) {
    let data = this.serialize();
    // ajax logic goes here

    let opts = this.options;

    $.ajax({
      method: opts.method,
      url: opts.url,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(data),
      success: function(res) {
        // handle success response
        callback(data);
      },
      error: function(xhr, o) {
        // catch error here
        console.log(xhr, o);
      }
    });

  }

  // final callback, toggling _form to hide and _complete to show
  complete(data = {}) {
    this.el.find('[data-placeholder="ep-email"]').text(data.email);
    this.el.addClass('complete');
    ScrollControl.scrollTo(this.el.prev('h2'));
  }

  // reset
  reset() {

  }

}

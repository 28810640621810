// returns scrolling offset
// http://stackoverflow.com/questions/10564680/get-div-position-top-in-javascript
function getScrollOffset( el ) {
    var _x = 0;
    var _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

// returns document offset
// http://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
function getDocumentOffset(el) {
    var box = el.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}

/*

  private Class ScrollEvent

  Used by the ScrollControl, this is merely a description
  of a specific interaction when a certain scroll limit has
  been reached. Handles scroll-in and scroll-out, as well as
  the scroll direction (up or down).

  @arg element [HTMLElement] - target element to relate to
  @arg options [Object] - object containing some optional parameters
    @param treshold [Number] - manual entry of a treshold
    @param autoTreshold [Boolean] - set to true to auto-calculate the treshold (w/ getBoundingClientRect)
    @param autoTresholdCorrection [Number] - use to correct the auto-treshold to improve accuracy in case things do not work as expected

 */
class ScrollEvent {

  constructor(el, options) {

    if(!options) var options = {};

    this.element          = el;
    this.hold             = false;
    this.inHandler        = null;
    this.outHandler       = null;
    this.fromHandler      = null;
    this.in               = undefined;
    this.offsetTop        = getScrollOffset(this.element).top;
    this.treshold         = options.treshold ? options.treshold : 0;

    // automatically pick up on the treshold by setting it to the height
    // of the element. This way, the callbacks get fired if we are really PAST
    // the element.
    if(options.autoTreshold) {
      var rect = this.element.getBoundingClientRect();
      this.treshold = rect.height;

      if(options.autoTresholdCorrection) {
        this.treshold += options.autoTresholdCorrection;
      }

    }

    // some extra properties for fixed headers and stuff, some metadata is needed here.
    this.documentOffset   = getDocumentOffset(this.element);
  }

  /*
    ScrollEvent.update
    @description - checks if a callback needs to be fired
    @arg scrollPos [Number] - current scroll position in document
    @arg e [Object or Null] - event object from the scrollHandler
    @return ScrollEvent
   */
  update(scrollPos, e) {

    // freeze updating if hold is set for this ScrollEvent
    if(this.hold) return;

    this.offsetTop = getScrollOffset(this.element).top;

    if(scrollPos >= this.documentOffset.top + this.treshold) {

      if(this.in === false && typeof this.inHandler === 'function') {
        this.inHandler(this.element, scrollPos, e);
      }

      if(typeof this.fromHandler === 'function') {
        this.fromHandler(this.element, scrollPos, e);
      }

      this.in = true;

    } else {

      if(this.in === true && typeof this.outHandler === 'function') {
        this.outHandler(this.element, scrollPos, e);
      }

      this.in = false;
    }

    return this;

  }

  /*
    ScrollEvent.on
    @description - handler setter for when user scrolls PAST the object
    @arg fn [Function] - callback function
    @return ScrollEvent
   */
  on(fn) {

    // break out if this is not a handler
    if(!fn || typeof fn !== 'function') return this;
    this.inHandler = fn;

    // chainable
    return this;
  }

  /*
    ScrollEvent.off
    @description - handler setter for when user scrolls back the object
    @arg fn [Function] - callback function
    @return ScrollEvent
   */
  off(fn) {

    // break out if this is not a handler
    if(!fn || typeof fn !== 'function') return this;
    this.outHandler = fn;

    // chainable
    return this;
  }

  /*
    ScrollEvent.from
    @description - handler setter for when user scrolls past the 'on' clause,
                   this handler is fired from that point, untill the user scrolls
                   back to before that point.
    @arg fn [Function] - callback function
    @return scrollEvent
   */
  from(fn) {
    // break out if this is not a handler
    if(!fn || typeof fn !== 'function') return this;
    this.fromHandler = fn;

    // chainable
    return this;
  }

}

/*

  public Class ScrollControl

  controls general scroll behavior and catches up on elements that
  reposition during scroll.

 */
export class ScrollControl {

  constructor() {
    this.scrollEvents = [];
    this.scrollCallbacks = [];
    this.initialise();
  }

  /*
    ScrollControl.initialise
    @description - initialises itself and bind event listeners
    @return void
   */
  initialise() {

    var _self = this;

    window.addEventListener('scroll', function(e) {
      _self.scrollHandler(e);
    });

  }

  /*
    ScrollControl.scrollHandler
    @description - callback for window.scroll event
    @arg e [Object] - event object from that event
    @return void
   */
  scrollHandler(e) {
    var _this = this;
    var scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
    this.scrollEvents.forEach(function(evt) {
      evt.update(scrollPos, e);
      for(var cb in _this.scrollCallbacks) {
        _this.scrollCallbacks[cb].apply(this, [scrollPos, e]);
      }
    });
  }

  /*
    ScrollControl.registerScrollEvent
    @description - creates a new scroll event
    @arg element [HTMLElement] - target element
    @arg options [object] - configuration to pass to ScrollEvent
    @return ScrollEvent
   */
  registerScrollEvent(element, options) {
    var evt = new ScrollEvent(element, options);
    this.scrollEvents.push(evt);
    return evt;
  }

  /*
    ScrollControl.on
    @description - callback listener for scroll events handled through the scrollcontrol
    @arg fn [Function] - callback function for onscroll events
   */
  on(fn) {
    if(fn && typeof fn === 'function') {
      this.scrollCallbacks.push(fn);
    } else {
      return false;
    }
  }

  /*
    static fn ScrollControl.initScrollTo

    Scroll utility for animating to other points on a page. works out-of
    the box after this function has been called.

    Link:
    <a href="#some-anchor" data-scroll-to>Click me</a>

    Target:
    <div id="some-anchor">...</div>

   */
  static initScrollTo() {

    $('[data-scroll-to]').bind('click', e => {
      e.preventDefault();
      let $self = $(e.currentTarget);
      let target = $self.attr('href');

      if($(target).length) {
        $("html,body").animate({
          scrollTop: $(target).offset().top - ($("#primary-nav").height()+20)
        }, {
          easing: "swing",
          duration: 500
        });
      }

    });

  }

  // basic implementation of above, but this is a seperate function that
  // will scroll to any part given to the function.
  static scrollTo(target, callback = function() {}) {
    if($(target).length) {

      var distance = $(target).offset().top;

      if($("#primary-nav").hasClass('in')) {
        distance = distance - ($("#primary-nav").height()+20);
      }

      $("html,body").animate({
        scrollTop: distance
      }, 500, callback);
    }
  }

}

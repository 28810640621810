const dogeVocabulary = [
  "wow",
  "much cuckoo",
  "so dubstep",
  "many sandbox",
  "so automated",
  "super analysis",
  "aww yea",
  "420 blaze it",
  "cuckoo sandbox",
  "automated malware analysis",
  "your malware is no party for us",
  "anionmous",
  "never forgetti",
  "spaghetti spaghetti",
  "hello i am doge",
  "brb compiling",
  "brb sandboxing",
  "afk cuckoo",
  "man woman",
  "congratulatiuhns",
  "u found teh konameh code",
  "1337",
  "very website",
  "interwebs-catopalypse is near",
  "do u even sandbox m8",
  "so intense",
  "much wow",
  "very doge",
  "u dun kno me!",
  "silehcun valleh",
  "illuminati confurmd",
  "amazing ux",
  "crazy frontend",
  "all colours",
  "so trippy",
  "many vms",
  "much network routing",
  "also on droid",
  "UNIX IS PURRFECT",
  "I pentest ur proxy?",
  "npm rocks",
  "yeah nodejs",
  "itsec rox!",
  "cuckoo extreme mode",
  "got pacman?",
  "cuckooman => pacman",
  "respect my securiteeeeh"
];

// renders an iFrame to load pacman in.
function letsPlayPacman(ctx) {
  let iframe = document.createElement('iframe');
  iframe.classList.add('easter-egg-pacman');
  ctx.append($(iframe));
  iframe.src = "http://pacman.platzh1rsch.ch/";
  iframe.scrolling = false;
  border: 0;
}

// blabber around with catchy comic sans lines, that spawn randomly across the
// screen
function comicSansDrop(ctx, options) {

  function blabber(override) {

    let word, x, y, fontSize, line;

    if(!override) {
      word = dogeVocabulary[Math.floor(Math.random() * dogeVocabulary.length)];
      y = Math.floor(Math.random() * $(window).height());
      x = Math.floor(Math.random() * $(window).width());
      fontSize = Math.floor(Math.random() * 75) + 50;
    } else {
      word = override.text ? override.text : "LOL";
    }

    line = $("<p class='easter-egg-word' />", {
      class: 'easter-egg-word'
    });

    if(override && override.center) {
      line.addClass('center');
    }

    line.html(word);

    if(!line.override) {
      line.css({
        left:`${x}px`,
        top: `${y}px`,
        fontSize: override && override.fontSize ? `${override.fontSize}px` : `${fontSize}px`
      });
    }

    ctx.append(line);

    setTimeout(function() {
      line.fadeOut(1000, function() {
        line.remove();
      });
    }, override ? override.duration : 2000);
  }

  if(!options) {
    return setInterval(blabber, 461);
  } else {
    blabber(options);
  }
}

// feeds a line of acid to the context, making it all super duper trippy.
function feedAcidToContext($ctx) {
  // css-referenced colors
  let current = undefined;
  let colors = ['red','green','blue','yellow','purple'];

  // forget current color
  function amnesia() {
    for(let c in colors) {
      $ctx.removeClass(colors[c]);
    }
  }

  function tripVisual() {
    let target = colors[Math.floor(Math.random() * colors.length)];

    if(current == target) {
      tripVisual();
    } else {
      $ctx.addClass(target);
      current = target;
    }
  }

  return setInterval(function() {
    amnesia();
    tripVisual();
  }, 461);
}

/*
  Creates the effects + their DOM.
  this happens here, so the easter egg is considered
  'super-secret' as there are definitely no 'traces' to see
  in the html for a hint...
 */
function goDubstep() {

  let $ctx = $("<div />", {
    class: "easter-egg-context"
  });

  let $doge = $("<div />", {
    class: 'easter-egg-doge'
  });

  let $dogeAccJ = $("<div />", {
    class: 'easter-egg-doge-accessory-j'
  });

  let $dogeAccG = $("<div />", {
    class: 'easter-egg-doge-accessory-g'
  });

  // play knife party
  let audio = document.createElement('audio');
  let $audio = $(audio);
  audio.src = '/assets/images/easteregg/easter-egg.mp3';
  audio.loop = true;
  audio.style.opacity = 0;

  $doge.append($dogeAccJ, $dogeAccG);
  $ctx.append($doge, $audio);

  return {
    html: $ctx,
    doge: $doge,
    dogAccJ: $dogeAccJ,
    dogAccG: $dogeAccG,
    audio: audio
  };
}

/*
  This class handles the easter egg (or 'konami trigger').
 */
export default class EasterEgg {
  constructor() {
    this.konami = null;
    this.active = false;
    this.context = null;
    this.acid = null;
    this.thug = null;
    this.dropthebass = null;
    this.comicsanswords = null;
    this.pacman = null;
    this.initialise();
  }

  initialise() {
    this.konami = Konami(this.activate.bind(this));
    return this;
  }

  activate() {
    this.active = true;
    this.context = goDubstep();
    $('body').append(this.context.html);

    // thug life
    this.thug = setTimeout(() => {
      this.context.dogAccJ.addClass('in');
      this.context.dogAccG.addClass('in');
    }, 3000);

    // start awesome dubstep interlude
    this.context.audio.play();

    // pre-blabber something weird and stupid
    comicSansDrop(this.context.html, {
      text: 'a totally unrelated and irrelevant<br />wild konami doge appeared',
      center: true,
      fontSize: 50,
      duration: 5000
    });

    // go dubstep on the drop
    this.dropthebass = setTimeout(() => {
      this.context.html.addClass('in');
      this.context.doge.addClass('in');
      this.acid = feedAcidToContext(this.context.html);
      this.comicsanswords = comicSansDrop(this.context.html);
      this.pacman = letsPlayPacman(this.context.html);
    }, 7376);

  }

  deactivate() {

  }

}

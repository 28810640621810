/*
  Creates a mapping from html for the carousel that can be used in the controller.
 */
function collect(query) {
  var ret = [];
  query.each((i, el) => {
    var el = $(el);
    ret.push({
      id: i,
      el: el,
      active: i == 1 ? true : false,
    });
  });
  return ret;
}

/*
  Carousel class controller
 */
export default class Carousel {

  // defines the class
  constructor(el, options = {}) {

    this.options = $.extend(options, {
      autoplay: true, // whether autoplay is activated
      autoplayInterval: 5000 // duration of autoplay intervals
    });

    this.el = el;
    this.$next = this.el.find('.carousel-next');
    this.$prev = this.el.find('.carousel-prev');

    this.pages        = collect(this.el.find('.carousel-item'));
    this.total        = this.pages.length;
    this.current      = 1;
    this.currentPage  = null;
    this.disabled     = false;
    this.autoplay     = null;

    this.initialise();
  }

  // general init, binds listeners
  initialise() {

    // set the current page
    this.currentPage = this.pages[0];

    if(this.total == 1) {
      // disable the auto-slider
      this.disabled = true;
      this.options.autoplay = false;
      // hide the index buttons
      this.$next.hide();
      this.$prev.hide();
    }

    // next click handler
    this.$next.bind('click', e => {
      e.preventDefault();
      this.next();
    });

    // prev click handler
    this.$prev.bind('click', e => {
      e.preventDefault();
      this.prev();
    });

    // starts an autoplay sequence if defined
    if(this.options.autoplay) {
      this.autoplay = setInterval(() => {
        this.next();
      }, this.options.autoplayInterval);
    }

  }

  // does a next action
  next() {

    // prevent weird css transition glitches from happening, one interaction at a time please.
    if(this.disabled) return;

    if(this.current+1 <= this.total) {
      this.current += 1;
    } else {
      this.current = 1;
    }

    this.switchActive();

  }


  // does a prev action
  prev() {

    // prevent weird css transition glitches from happening, one interaction at a time please.
    if(this.disabled) return;

    if(this.current == 1) {
      this.current = this.total;
    } else {
      this.current -= 1;
    }

    this.switchActive();

  }

  // transition handler
  switchActive() {

    let self = this;
    self.disabled = true;

    if(this.currentPage) {
      let activePage = this.currentPage.el;
      activePage.addClass('out');

      setTimeout(function() {
        activePage.removeClass('out active');
        self.disabled = false;
      }, 800);
    }

    this.currentPage = this.pages[this.current-1];
    this.currentPage.el.addClass('active');

  }

}

import { Utilities } from './util';

const CUCKOO_DEMO = {

  // lists the draggable preset-samples in the display list
  SAMPLES: [
    {
      id: Utilities.randomID(4),
      name: 'malicious-archive-package.zip',
      type: 'archive',
      sample_data: {}
    },
    {
      id: Utilities.randomID(4),
      name: 'malicious-office-document.docx',
      type: 'office',
      sample_data: {}
    },
    {
      id: Utilities.randomID(4),
      name: 'malicious-pdf-document.pdf',
      type: 'pdf',
      sample_data: {}
    }
  ],

  // translates types to icons
  ICON_TRANSLATE: {
    default: 'fa-file-o',
    archive: 'fa-file-archive-o',
    office: 'fa-file-word-o',
    pdf: 'fa-file-pdf-o'
  },

  // lists the available commands for the
  // micro-terminal
  AVAILABLE_COMMANDS: {
    'process': {},
    'submit': {},
    'report': {}
  },

  // the 'package' the commands belongs to.
  COMMAND_NAMESPACE: 'cuckoo'

}

export default CUCKOO_DEMO;

const Utilities = {

  // credit: https://stackoverflow.com/questions/46155/validate-email-address-in-javascript
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  // credit: https://stackoverflow.com/questions/161738/what-is-the-best-regular-expression-to-check-if-a-string-is-a-valid-url
  validateURL(url) {
    try {
      new URL(url);
      return true;
    } catch(e) {
      return false;
    }
  },

  // creates a handlebars alert
  createAlert(message, variation, hideClose) {

    var ret = {
      html: null,
      // a simple function for binding primary interactions
      // to this element
      init: function(html) {
        html.find('.close').on('click', function(e) {
          e.preventDefault();
          $(this).parent('.alert').remove();
        });
      }
    }

    if(Handlebars.templates.alert) {
      ret.html = Handlebars.templates.alert({
        message: message,
        variation: variation,
        hideClose: hideClose
      });
    }

    return ret;

  },

  // generates random task id's
  // @arg len - length of the id generated
  randomID(len) {

    let allowedCharacters = "123456789";
    let id = "";
    let char;

    for(let i = 0; i < len; i++) {
      char = allowedCharacters.charAt(Math.floor(Math.random() * allowedCharacters.length));
      id += char;
    }

    return id;

  },

  // 'locks' the entire page from scrolling
  toggleScrollLock(mode = "toggle") {

    // mode == toggle|true|false

    if(mode == "toggle") {
      $("body").toggleClass("scroll-lock");
    } else {
      $("body").toggleClass("scroll-lock",  mode);
    }

  },

  // Activate the sunset/sunrise 'AI' api
  // this function returns only usefull data that is being
  // used to function well. For any more, this is the function-to-go
  // to change all the parameters.
  getWebsiteTime(cb = function(){}) {

    // return product
    let ret = {
      err: null,
      lat: null,
      lon: null,
      sunset: null,
      sunrise: null
    };

    // some options for tweaking the results.
    let geolocationOptions = {
      enableHighAccuracy: false, // 'somewhere around here'-ish results will do perfectly fine.
      timeout: 5000,
      maximumAge: Infinity
    }

    // user did not give us their location.
    function dismissed(err) {
      ret.err = err;
      cb(ret);
    }

    // user gave us their location
    function permitted(position) {

      ret.lat = position.coords.latitude;
      ret.lon = position.coords.longitude;

      // get the times variable from suncalc
      let times = SunCalc.getTimes(new Date(), ret.lat, ret.lon);
      ret.sunset = moment(new Date()).isSameOrAfter(times.sunset);
      ret.sunrise = moment(new Date()).isSameOrAfter(times.sunrise);

      cb(ret);
    }

    // first off, we need to get the location of the user.
    // user will be asked permission for this.
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(permitted, dismissed, geolocationOptions);
    }

    // returns void, uses a callback
    return null;
  }

}

export default Utilities;

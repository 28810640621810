// initialises all the breakpoints with more specification and
// internal parameters
var mapBreakpoints = (breakpoints) => {
  var bpMapped = [];
  for(var bp in breakpoints) {
    bpMapped.push({
      name: bp,
      width: breakpoints[bp],
      callbacks: [],
      gt: false,
      lt: false,
      active: false,
      _becomesGT: function() {

        this.lt = false;
        this.gt = true;

        this.active = true;

        this.callbacks.filter(function(cb) {
          return cb.type == 'smallerThan';
        }).forEach(function(cb) {
          cb.active = false;
        });

      },
      _becomesLT: function() {

        this.lt = true;
        this.gt = false;

        this.active = false;

        this.callbacks.filter(function(cb) {
          return cb.type == 'biggerThan';
        }).forEach(function(cb) {
          cb.active = false;
        });

      }
    });
  }
  return bpMapped;
}

/*
  ResponsiveEvent is a trigger handler for the various
 */
class ResponsiveEvents {

  constructor(props) {
    this.current = undefined;
    this.breakpoints = mapBreakpoints(props.breakpoints || {});
    this._initialise();
  }

  // binds listeners and stuff like that
  _initialise() {

    const _this = this;

    // define the gt and lt handlers on breakpoints once
    for(var bp in this.breakpoints) {
      if(window.outerWidth > this.breakpoints[bp].width) {
        this.breakpoints[bp]._becomesGT();
      } else {
        this.breakpoints[bp]._becomesLT();
      }
    }

    window.addEventListener('resize', function(e) {
      _this._check();
    });

    // initialize once
    this._check();

  }

  // utility function that each'es over the breakpoints
  // object and fires a callback for each result
  _eachBreakpoint(callback) {
    for(var bp in this.breakpoints) {

      let result = callback.call(this, this.breakpoints[bp]);

      // killswitch, if a callback returns false, the loop stops.
      if(result === false) {
        break;
      }
    }
  }

  // validatates breakpoints against current validations
  // and fires callbacks based upon the effective results
  _check(cb) {

    const _this = this;

    // validate existence of callback
    if(!cb) var cb = function() {};

    this._eachBreakpoint(breakpoint => {
      this._fireCallbacks(breakpoint, window.innerWidth);
    });

    // run callback
    if(typeof cb === 'function') cb.call(this, null);

  }

  // registers a callback onto a breakpoint. The callback object
  // consumes a few more properties for targeted execution. (fire once if [condition]*)
  _registerCallback(breakpointName, type, callback) {

    var target = this.breakpoints.filter(function(bp) {
      return bp.name == breakpointName;
    })[0];

    if(target) {
      target.callbacks.push({
        fn: callback,
        type: type,
        active: false
      });
    }

    return this;
  }

  // finds callbacks based on breakpoint name and execution type
  _fireCallbacks(breakpoint, currentWidth) {

    var _this = this;

    // establish the type of callback we are looking for
    var type = currentWidth < breakpoint.width ? 'smallerThan' : 'biggerThan';

    // run callback flusher (private function inside callback object)
    switch(type) {
      case "smallerThan":
        if(breakpoint.gt) {
          breakpoint._becomesLT();
        }
      break;
      case "biggerThan":
        if(breakpoint.lt) {
          breakpoint._becomesGT();
        }
      break;
    }

    // find callback
    var cbs = breakpoint.callbacks.filter(function(callback) {
      return callback.type == type;
    }) || [];

    // compare and fire if they match to be fired
    cbs.forEach(function(cb) {
      if(!cb.active) {
        cb.fn.call(_this, breakpoint, cb);
        cb.active = true;
      }
    });

  }

  // register callbacks to 'bigger than width' clause
  biggerThan(breakpointName, callback) {
    return this._registerCallback(breakpointName, 'biggerThan', callback);
  }

  // register callbacks to 'smaller than width' clause
  smallerThan(breakpointName, callback) {
    return this._registerCallback(breakpointName, 'smallerThan', callback);
  }

  // updates current active breakpoint
  updateCurrent() {
    let bp = this.breakpoints.reverse();
    let current = bp[0];
    let pos = 0;
    bp.forEach((breakpoint, pos) => {
      if(breakpoint.gt) {
        current = breakpoint;
        pos = pos;
      }
    })
    this.current = bp[pos+1];
  }

}

export default ResponsiveEvents;

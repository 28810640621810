import { Utilities } from '../util';

// actually controls the form, this class is being
// instantiated by NewsLetter
class NewsLetterForm {

  constructor(form) {

    var self = this;
    this.form = form;

    this.$email = this.form.find('input[name=email]');
    this.$terms = this.form.find('input[name=agree_terms]');

    this.form.on('submit', function(e) {
      e.preventDefault();

      if(self.form.find('.alert')) self.form.find('.alert').remove();

      self.subscribe();
    });

  }

  // creates listeners on the various form
  // elements that participate in this interaction
  subscribe() {

    var email = this.$email.val();
    var agreed = this.$terms.is(':checked');

    // check 1: is the email correct?
    if(!Utilities.validateEmail(email)) {
      this.notify('Incorrect email, try again.');
      return;
    }

    // check 2: did the user accept the terms for subscribing?
    if(!agreed) {
      this.notify('Please read the terms.');
      return;
    }

    // submit the data, please note that this is
    // currently a mocked api endpoint (by Ratchet). It
    // does not do anything except for returning mocked
    // json.
    $.post('/api/newsletter-subscribe', {
      email: email,
      agreed: agreed
    }, function(response) {
      if(response.success) {
        this.notify('You have been subscribed! Thank you for your interest in Cuckoo Sandbox.', true);
        // delete the input controls
        this.form.find('.input-control').remove();
      } else {
        this.notify('something went wrong, please try again later.');
      }
    }.bind(this))

  }

  // a function that will take care of user feedback to
  // the frontend when the user input is error-ous.
  notify(message, hideClose = false, variation = 'small') {

    // first, create the alert, prepend it to the form
    // and then bind the close action (a.init) to this alert
    // to enable dismissability.
    var a = Utilities.createAlert(message, variation, hideClose);
    var ah = $(a.html)
    this.form.prepend(ah);
    a.init(ah);

    return false;
  }

}

// make multiple news letter items possible by
// using a 'gateway' controller class, creating
// NewsLetterForm instances for each form in the
// matched element set.
class NewsLetter {

  constructor(sel) {

    if(!sel) return;

    sel.each(function() {
      if($(this).prop('tagName') == 'FORM') {
        var nl = new NewsLetterForm($(this));
        $(this).data('newsLetter', nl);
      }
    });

  }

}

export default NewsLetter;

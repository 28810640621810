// declare configuration and global parameters
const SiteConfig = {

  credentials: {
    RECAPTCHA_KEY: '6LevSysUAAAAAN8iUC7yKNoUKYeXuJbbx18i5TVI' // google site key for reCAPTCHA
  },

  /*
    Declares responsive breakpoints, these settings HAVE to be the same
    as configured in _variables.scss $ui-breakpoints.
   */
  responsive: {
    'small-desktop': 1000,
    'tablet': 768,
    'phone': 480
  },

  /*
    Declares a variety of states for the website. These states are mutable
    by the website javascript as they are persisted globally.
   */
  state: {
    'phone': false,
    'mobile-nav-open': false,
    'is-blurred': false,
    'responsive_mode': 'default'
  },

  /*
    Declares a collection of site-wide helpers and utilities
   */
  util: {
    phoneIn: () => {
      SiteConfig.state.phone = true;
      document.body.classList.add('is-phone');
    },
    phoneOut: () => {
      SiteConfig.state.phone = true;
      document.body.classList.remove('is-phone');
    }
  },

  /*
    Handles quick UI transitions and states that are page-wide.
   */
  ui: {
    blurIn: () => {
      $(".transition-filter").addClass('blurred');
      SiteConfig.state['is-blurred'] = true;
    },
    blurOut: () => {
      $(".transition-filter").removeClass('blurred');
      SiteConfig.state['is-blurred'] = false;
    }
  },

  /*
    contains general information about the availability of components and
    modules on a rendered page. Basically this object will contain javacript-controlled
    HTML elements, to check against execution of scripts in main.babel
   */
  page: {
    sidebar: false,
    header: false,
    nav: false,
    blogArticle: false,
    teamMembers: false,
    form: false,
    consent: false
  }

}

export default SiteConfig;

import { Utilities } from '../util';

// stores all zoomers
let zoomers = [];

// unspawns all zoomers
function unspawnAll() {
  zoomers.forEach(zoomer => {
    zoomer.unspawn();
  });
}

// returns the active zoomer
function activeZoomer() {
  return zoomers.filter(zoomer => {
    return zoomer.active
  });
}

// binds an 'press esc-to-close' interaction
window.addEventListener('keyup', e => {
  if(e.keyCode == 27) {
    unspawnAll();
  }
});

//
// ImageZoom constructor class
//
class ImageZoom {

  // defines the imagezoom class
  constructor(options = {}) {
    this.image = options.image;
    this.href = options.href;
    this.annotation = options.annotation;
    this.template = options.template;
    this.html = null;
    this.active = false;

    this.initialise();
  }

  // attaches listeners
  initialise() {

    $(this.image).addClass('image-zoom-enabled');

    $(this.image).bind('click', e => {
      this.spawn();
    });

  }

  // renders the image zoom component
  spawn(cb) {

    this.active = true;

    this.html = $.parseHTML(this.template({
      href: this.href,
      alt: this.alt,
      annotation: this.annotation
    }));

    $("body").append(this.html);

    // bind in-component interactions
    $(this.html).find('[href="imagezoom:close"]').bind('click', e => {
      e.preventDefault();
      this.unspawn();
    });

    setTimeout(() => {
      Utilities.toggleScrollLock(true);
      $(this.html).addClass('in');
    }, 100);

  }

  // 'unspawn' (or destroy) an imagezoomer
  unspawn() {

    if(this.html) {
      $(this.html).removeClass('in');
      setTimeout(() => {
        $(this.html).remove();
        Utilities.toggleScrollLock(false);
        this.active = false;
      }, 300);
    }
  }

}

// implement as find-all-and-init jquery plugin (if we have jquery loaded)
if(jQuery !== undefined) {
  $.fn.ImageZoom = function(options = {}) {

    let config = $.extend({
      template: null // a slot for a Handlebars template
    }, options);

    this.each((i, img) => {
      let iz = new ImageZoom({
        image: img,
        href: $(img).attr('src'),
        template: config.template
      });

      $(img).data('imageZoom', iz);
      zoomers.push(iz);
    });

  }
}

export {
  ImageZoom,
  unspawnAll as unspawn,
  activeZoomer as active
};
